<template>
  <div class="RechargeRecord">
    <div class="recharge-inner">
      <div class="recharge-tit">
        <el-row :gutter="20">
          <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2">
              <label for="">日期：</label>
          </el-col>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10">
                <el-date-picker
                  v-model="time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00','23:59:59']">
                </el-date-picker>
          </el-col>
         
          <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
              <el-button type="primary" plain @click="rechargeSearch()">查询</el-button>
              <el-button type="warning" plain @click="reset()">重置</el-button>
          </el-col>
        </el-row>
      </div>
       <el-table
        :data="allRechargeRecordList"
        style="width: 100%"
        border
        >
        <el-table-column
          label="订单号"
          width="100"
          prop="out_trade_no">
        </el-table-column>
        <el-table-column
            label="交易状态"
            width="100"
            prop="trade_status"
            >
          </el-table-column>
        <el-table-column
          label="订单金额"
          width="100"
          prop="total_amount">
        </el-table-column>
        <el-table-column
          label="实收金额"
          width="100"
          prop="receipt_amount">
        </el-table-column>
        <el-table-column
          label="开票金额"
          width="100"
          prop="invoice_amount">
        </el-table-column>
         <el-table-column
          label="支付金额"
          width="100"
          prop="buyer_pay_amount">
        </el-table-column>
         <el-table-column
          label="退款金额"
          width="100"
          prop="refund_fee">
        </el-table-column>
         <el-table-column
          label="订单标题"
          width="200"
          prop="subject">
        </el-table-column>
        <el-table-column
          label="交易创建时间"
          width="200"
          prop="gmt_create">
        </el-table-column>
        <el-table-column
          label="交易付款时间"
          width="200"
          prop="gmt_payment">
        </el-table-column>
        <el-table-column
          label="交易退款时间"
          width="200"
          prop="gmt_refund">
        </el-table-column>
        <el-table-column
          label="支付类型">
          <template slot-scope="scope">
            <span>{{scope.row.pay_type == 1 ? '微信' : '支付宝'}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="per_page"
            layout="total, sizes, prev, pager, next, jumper"
            :total= count>
        </el-pagination>
    </div>
    </div>
  </div>
</template>

<script>
    import RechargeRecord from './RechargeRecord.js'
    export default RechargeRecord
</script>

<style scoped>
    @import './RechargeRecord.css';
</style>