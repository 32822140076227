import RechargeRecord from './RechargeRecord.vue'
import axios from 'axios'
export default{
    components:{
        RechargeRecord
    },
    data(){
        return {
            allRechargeRecordList: [],
            currentPage:1,
            page:1,
            per_page:10,
            count:0,
            time:'',
        }
    },
    created(){
        this.rechargeSearch()
    },
    methods: {
        handleSizeChange(val) {
            this.per_page = val
            this.rechargeSearch(this.page,this.per_page)
        },
        handleCurrentChange(val) {
            this.page = val
            this.rechargeSearch(this.page,this.per_page)
        },
        
        rechargeSearch(page,per_page){
            var data = {
                page:page || 1,
                per_page: per_page || 10
            }
            this.currentPage = data.page
            this.page = data.page
            this.per_page = data.per_page
            if(this.time){
                data.start_time = this.time[0]
                data.end_time = this.time[1]
            }
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL2+ '/pay/PayPerson',{
              params:data
            })
            .then(res=>{
                if(res.data.status_code == 0){
                    this.count = res.data.count
                    this.allRechargeRecordList = res.data.data
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        reset(){
            this.time=''
            this.rechargeSearch()
        }
    },
}